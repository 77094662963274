import { Box, Container, Typography } from '@material-ui/core'
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { createNameSpacedPath } from 'bl-health/utils'
import browserHistory from 'bl-health/utils/browserHistory'
import { ApplicationStrategy } from 'bl-health/types'
import { DMEInfoCard } from 'bl-health/components/InfoCard/DMEInfoCard'
import ProductPreviewer from 'bl-health/components/ProductPreviewer'
import { useDMEStore } from '../../store'
import useStyles from './styles'
import { UI } from '../../../global'
import {
  CUSTOMER_INFORMATION_STEP,
  EXISTING_CUSTOMER_PATH,
  SEARCH_PHYSICIAN_PATH,
} from '../../constants'
import CheckoutJourneyStepsWidget from '../../widgets/CheckoutJourneyStepsWidget'
import CustomerInformationForm from '../../forms/CustomerInformationForm'
import CheckoutJourneyWrapperWidget from '../../widgets/CheckoutJourneyWrapperWidget'

const CustomerInformationView: UI.ViewComponent<UI.LifeCycle> = observer(
  ({ onComponentDidMount }) => {
    const store = useDMEStore()
    const classes = useStyles()

    useEffect(() => {
      onComponentDidMount()
    }, [])

    function handleOnFormSuccess() {
      browserHistory.push(
        createNameSpacedPath(
          SEARCH_PHYSICIAN_PATH,
          store.context?.strategy as ApplicationStrategy
        )
      )
    }

    return (
      <Container className={classes.viewContainer}>
        <CheckoutJourneyWrapperWidget>
          {store.context.productInCart && (
            <ProductPreviewer
              brand={store.context.productInCart.brand}
              id={store.context.productInCart.id}
              imgUrl={store.context.productInCart.thumbnailImageUrl}
              name={store.context.productInCart.name}
              price={store.context.productInCart.price}
            />
          )}
          <CheckoutJourneyStepsWidget currentStep={CUSTOMER_INFORMATION_STEP} />
          <Typography color="primary" variant="h4">
            Let's get your insurance information.
          </Typography>
          <Typography
            gutterBottom
            className={classes.disclaimer}
            variant="body1"
          >
            We just need to verify your insurance is active.
          </Typography>
          {store.context.strategy === 'parts' && (
            <Box mb={4}>
              <DMEInfoCard
                content="Ordered your pump on Babylist Health?"
                link={EXISTING_CUSTOMER_PATH}
                linkText="Skip this step by entering your email"
              />
            </Box>
          )}
          <CustomerInformationForm onSuccess={handleOnFormSuccess} />
        </CheckoutJourneyWrapperWidget>
      </Container>
    )
  }
)

export default CustomerInformationView
