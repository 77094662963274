import { Factory } from 'fishery'
import faker from 'faker'
import { RegItem } from 'src/types/regItem'

export const regItemFactory = Factory.define<RegItem>(({ sequence }) => ({
  addedViaBabylist: false,
  canAddDirectlyToCart: false,
  categoryId: 123,
  disableReservations: false,
  fundedAmount: 123,
  giftCardOptions: [],
  goalAmount: 123,
  groupGiftingDisabledMessage: '',
  hasAtLeastOnePurchase: false,
  id: sequence,
  imgUrl: faker.image.imageUrl(),
  isActive: true,
  isAvailableOnBabylist: true,
  isBabylistProductDiscontinued: false,
  isBonus: false,
  isCrowdfund: false,
  isEditable: true,
  isElectronicGiftCard: false,
  isExternalRegistry: false,
  isFavor: false,
  isFavorite: false,
  isFirstRegItem: false,
  isGiftCard: false,
  isGoalComplete: false,
  isGroupGift: false,
  isPriceEditable: true,
  isReserved: false,
  isSomewhatReserved: false,
  itemType: 'product',
  isTitleEditable: true,
  offers: [],
  position: sequence,
  price: faker.commerce.price(),
  priceDetails: {
    listPrice: { price: '10.00' },
    msrp: { price: '12.00' },
  },
  primaryOfferUrl: '',
  private: false,
  productGtin: faker.datatype.uuid(),
  productId: 123,
  quantity: faker.datatype.number(),
  quantityNeeded: faker.datatype.number(),
  quantityPurchased: 0,
  quantityReserved: 0,
  registry: {
    id: 123,
    ownerId: 123,
  },
  registryDiscount: 1,
  registryId: 123,
  secondaryLinks: [],
  secondaryOffers: [],
  showGroupGiftToggle: false,
  title: faker.commerce.productName(),
  url: '',
  viglinkEnabled: false,
}))
