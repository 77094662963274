import React, { useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import Autocomplete from 'baby-ui/foundation/AutoComplete'
import {
  Box,
  Grid,
  Link,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import { useMutation, useQuery } from '@tanstack/react-query'
import classnames from 'classnames'
import LoadingButton from 'baby-ui/compounds/LoadingButton'
import TextField from 'baby-ui/foundation/TextField'
import { createFilterOptions } from '@material-ui/lab'
import { observer } from 'mobx-react-lite'
import { useTracking } from 'lib/babylistHealthAnalytics'
import {
  INSURANCE_NOT_FOUND_TEXT,
  UnsupportedInsuranceDialog,
  useUnsupportedInsuranceDialog,
} from '../../widgets/UnsupportedInsuranceDialog'
import { getInsuranceCatalog } from '../../requests'
import useStyles from './InsurancePickerForm.styles'
import { ThemeConstants } from '../../styles'
import {
  autocompleteInsuranceCarrierOptions,
  autocompleteValidationSchema,
  findInsuranceProviders,
  processInsuranceStates,
} from './InsurancePickerForm.utils'
import { useLayout } from '../../widgets/Layout'
import { PRIVACY_URL } from '../../constants'
import createLead from '../../requests/createLead'
import { useDMEStore } from '../../store'
import InsurancePickerFormSkeleton from './InsurancePickerFormSkeleton'
import FullScreenPanelTableWidget from '../../widgets/FullScreenPanelTableWidget/FullScreenPanelTableWidget'
import { INSURANCE_PANEL_NAME } from '../../widgets/FullScreenPanelTableWidget/FullScreenPanelTableWidget.constants'
import { pumpCatalogSelectedEvent } from '../../events/pumpCatalogSelectedEvent'

const filter = createFilterOptions<APIResponse.Insurance>()

interface FormValues {
  state: string
  insurance: string
  email?: string
}

export interface InsurancePickerFormProps {
  onInsuranceStateFieldFocus: () => void
  onInsuranceCarrierFieldFocus: () => void
  submitButtonText?: string
}

const InsurancePickerForm = observer(
  ({
    onInsuranceCarrierFieldFocus,
    onInsuranceStateFieldFocus,
    submitButtonText,
  }: InsurancePickerFormProps) => {
    const store = useDMEStore()
    const { data, isLoading } = useQuery({
      queryKey: ['insurancePicker'],
      queryFn: getInsuranceCatalog,
      refetchOnWindowFocus: false,
    })
    const layout = useLayout()
    const experimentGroup = layout?.experiment?.dmeUserReEngagementEmailCapture
    const { control, handleSubmit, errors, setValue } = useForm<FormValues>({
      validationSchema: autocompleteValidationSchema,
      validationContext: { experimentGroup },
      defaultValues: { state: store.insurance.state as string },
    })
    const isDesktop = useMediaQuery((theme: Theme) =>
      theme.breakpoints.up('sm')
    )
    const classes = useStyles()
    const stateDropdownRef = useRef<HTMLInputElement>(null)
    const [statePanelTableOpen, setStatePanelTableOpen] = useState(false)
    const [insurancePanelTableOpen, setInsurancePanelTableOpen] =
      useState(false)
    const { mutate: saveEmail, isLoading: isEmailLoading } = useMutation({
      mutationFn: createLead,
      onSuccess: () => {
        handleOnSubmitSuccess(true)
      },
      onError: () => {
        handleOnSubmitSuccess(true)
      },
    })
    const [insuranceStateValue, setInsuranceStateValue] = useState<
      string | null
    >(store.insurance.state)
    const [insuranceCarrierValue, setInsuranceCarrierValue] =
      useState<APIResponse.Insurance | null>(null)
    const unsupportedInsuranceDialog = useUnsupportedInsuranceDialog()
    const tracker = useTracking()
    const formRef = React.useRef<HTMLFormElement>(null)

    if (isLoading) {
      return <InsurancePickerFormSkeleton />
    }

    const variant = isDesktop ? 'contrast' : 'default'
    const allInsuranceStates = processInsuranceStates(data || [])
    const { allInsurances: allInsuranceCarriers, promotedInsurances } =
      findInsuranceProviders(insuranceStateValue || '', data || [])
    const isInsuranceCarrierFieldDisabled = !insuranceStateValue

    const handleOnSubmitSuccess = (emailProvided: boolean) => {
      pumpCatalogSelectedEvent(tracker)({
        insuranceProvider: insuranceCarrierValue!.company,
        insuranceProviderId: insuranceCarrierValue!.id,
        insuredState: insuranceStateValue!,
        emailProvided,
        emailExperimentGroup: experimentGroup || 'none',
      })

      store.insurance.saveState(insuranceStateValue!)
      formRef.current?.submit()
    }

    const formSubmitted = ({ email }: FormValues) => {
      if (email) {
        saveEmail({ email })
      } else {
        handleOnSubmitSuccess(false)
      }
    }

    const handleInsuranceStateFieldOnChange = (
      value: string | null,
      reason: string
    ) => {
      if (reason === 'clear') {
        setInsuranceStateValue(null)
        setValue('state', '')
        handleInsuranceCarrierFieldOnChange(null, 'clear')
        return
      }

      if (
        value !== null &&
        unsupportedInsuranceDialog.isUnsupportedState(value, data!)
      ) {
        unsupportedInsuranceDialog.showUnsupportedStateDialog(value)
        return
      }

      setInsuranceStateValue(value)
      setValue('state', value || '', true)
      handleInsuranceCarrierFieldOnChange(null, 'clear')
    }

    const handleInsuranceCarrierFieldOnChange = (
      value: APIResponse.Insurance | null,
      reason: string
    ) => {
      if (reason === 'clear') {
        setInsuranceCarrierValue(null)
        setValue('insurance', '')
        return
      }

      if (value && unsupportedInsuranceDialog.isUnsupportedProvider(value)) {
        setInsuranceCarrierValue(null)
        unsupportedInsuranceDialog.showUnsupportedProviderDialog()
        return
      }

      if (!value?.enabled) {
        setInsuranceCarrierValue(null)
        unsupportedInsuranceDialog.showUnsupportedProviderDialog(value?.company)
        return
      }

      setInsuranceCarrierValue(value as APIResponse.Insurance)
      setValue('insurance', value?.company || '', true)
    }

    const stateId = data?.find(
      (state) => state.stateName === insuranceStateValue
    )?.id

    return (
      <form
        action={store.context.formAction as string}
        method="post"
        ref={formRef}
        onSubmit={handleSubmit(formSubmitted)}
      >
        <input
          name="insurance_id"
          type="hidden"
          value={insuranceCarrierValue?.id}
        />
        <input name="state_id" type="hidden" value={stateId} />
        <UnsupportedInsuranceDialog
          onClose={() => {
            unsupportedInsuranceDialog.hideDialog('')
          }}
          {...unsupportedInsuranceDialog.dialogProps}
        />

        <FullScreenPanelTableWidget
          name="insuranceState"
          open={statePanelTableOpen}
          options={allInsuranceStates}
          selected={insuranceStateValue}
          title="Shipping State"
          onClose={() => setStatePanelTableOpen(false)}
          onOptionSelect={(value) => {
            handleInsuranceStateFieldOnChange(value, 'select-option')
            setStatePanelTableOpen(false)
          }}
        />

        <FullScreenPanelTableWidget
          featuredOptions={promotedInsurances}
          featuredOptionsSubheader="Top plans:"
          footer={
            <Box display="flex" justifyContent="end" width="100%">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link
                component="button"
                variant="body2"
                onClick={() => {
                  setInsurancePanelTableOpen(false)
                  unsupportedInsuranceDialog.showUnsupportedProviderDialog()
                }}
              >
                {INSURANCE_NOT_FOUND_TEXT}
              </Link>
            </Box>
          }
          getOptionLabel={(item) => item?.company as string}
          name={INSURANCE_PANEL_NAME}
          open={insurancePanelTableOpen}
          options={allInsuranceCarriers}
          optionsSubheader="All plans:"
          selected={insuranceCarrierValue}
          title="Insurance Carrier"
          onClose={() => setInsurancePanelTableOpen(false)}
          onOptionSelect={(insuranceCarrier) => {
            handleInsuranceCarrierFieldOnChange(
              insuranceCarrier,
              'select-option'
            )
            setInsurancePanelTableOpen(false)
          }}
        />

        <Grid container spacing={ThemeConstants.BASE_SPACING}>
          <Grid
            item
            xs={12}
            onClick={() => {
              if (!isDesktop) {
                setStatePanelTableOpen(true)
              }
            }}
          >
            <Controller
              aria-required
              as={(props) => (
                <Autocomplete<string>
                  {...props}
                  value={insuranceStateValue}
                  onChange={(_, newValue, reason) =>
                    handleInsuranceStateFieldOnChange(newValue, reason)
                  }
                />
              )}
              className={classnames({
                [classes.autoCompleteFieldBg]: isDesktop,
                [classes.errorStyle]: !!errors.state && isDesktop,
                [classes.mobileField]: !isDesktop,
              })}
              control={control}
              disableClearable={!isDesktop}
              label="Shipping State"
              name="state"
              options={allInsuranceStates}
              textFieldProps={{
                error: !!errors.state,
                helperText: errors.state?.message,
                inputProps: { onFocus: onInsuranceStateFieldFocus },
                inputRef: stateDropdownRef,
                readOnly: !isDesktop,
              }}
              variant={variant}
            />
          </Grid>

          <Grid
            item
            xs={12}
            onClick={() => {
              if (!isDesktop && !isInsuranceCarrierFieldDisabled) {
                setInsurancePanelTableOpen(true)
              }
            }}
          >
            <Controller
              aria-required
              as={(props) => (
                <Autocomplete<APIResponse.Insurance>
                  {...props}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params)
                    filtered.push({
                      company: INSURANCE_NOT_FOUND_TEXT,
                    } as APIResponse.Insurance)
                    return filtered
                  }}
                  getOptionLabel={(option) => option.company}
                  value={insuranceCarrierValue}
                  onChange={(_, newValue, reason) =>
                    handleInsuranceCarrierFieldOnChange(newValue, reason)
                  }
                />
              )}
              className={classnames({
                [classes.root]: isDesktop,
                [classes.autoCompleteFieldBg]:
                  !isInsuranceCarrierFieldDisabled && isDesktop,
                [classes.errorStyle]: !!errors.insurance && isDesktop,
                [classes.mobileField]:
                  !isInsuranceCarrierFieldDisabled && !isDesktop,
              })}
              control={control}
              disabled={isInsuranceCarrierFieldDisabled}
              groupBy={({ promoted }: APIResponse.Insurance) =>
                promoted ? 'Top plans:' : 'All plans:'
              }
              getOptionSelected={(
                option: APIResponse.Insurance,
                value: APIResponse.Insurance
              ) => option.id === value.id}
              label="Insurance Carrier"
              name="insurance"
              options={autocompleteInsuranceCarrierOptions(
                promotedInsurances,
                allInsuranceCarriers
              )}
              textFieldProps={{
                error: !!errors.insurance,
                helperText: isInsuranceCarrierFieldDisabled
                  ? 'Please select your shipping state first'
                  : errors.insurance?.message,
                readOnly: true,
                inputProps: { onFocus: onInsuranceCarrierFieldFocus },
              }}
              variant={variant}
            />
          </Grid>
          {experimentGroup && experimentGroup !== 'none' && (
            <Grid item xs={12}>
              <Controller
                fullWidth
                as={TextField}
                className={classnames({
                  [classes.emailField]: isDesktop,
                  [classes.emailErrorField]: !!errors.email && !isDesktop,
                })}
                control={control}
                error={!!errors.email}
                helperText={errors.email?.message}
                label={
                  experimentGroup === 'required'
                    ? 'Email *'
                    : 'Email (optional)'
                }
                name="email"
                type="email"
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <LoadingButton
              fullWidth
              color="secondary"
              loading={isEmailLoading}
              size="large"
              type="submit"
              variant="contained"
            >
              {submitButtonText ?? 'Select my breast pump'}
            </LoadingButton>
          </Grid>

          {experimentGroup && experimentGroup !== 'none' && (
            <Grid item className={classes.resourcesText} xs={12}>
              <Typography component="p" variant="caption">
                Your privacy is important to us. By continuing, you agree to our{' '}
                <a
                  className={classnames({ [classes.privacyText]: isDesktop })}
                  href={PRIVACY_URL}
                  rel="noreferrer"
                  target="_blank"
                >
                  Privacy Policy
                </a>
                .
              </Typography>
            </Grid>
          )}
        </Grid>
      </form>
    )
  }
)
export default InsurancePickerForm
