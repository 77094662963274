import { useEffect, useState } from 'react'
import TextField from 'baby-ui/foundation/TextField'
import BabyUICheckbox from 'baby-ui/foundation/CheckBox'
import { Controller, useForm } from 'react-hook-form'
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Link,
} from '@material-ui/core'
import MaskedPhoneNumberTextField from 'baby-ui/foundation/MaskedPhoneNumberTextField'
import Alert from '@material-ui/lab/Alert'
import LoadingButton from 'baby-ui/compounds/LoadingButton'
import TermsAndConditions from 'baby-ui/compounds/TermsAndConditions'
import { observer } from 'mobx-react-lite'
import { useMutation } from '@tanstack/react-query'
import { track, useTracking } from 'lib/babylistHealthAnalytics'
import DateTextField from 'baby-ui/compounds/DateTextField'
import { ApplicationStrategy } from 'bl-health/types'
import { customerInformationFormFactory } from 'factories'
import { inDebugMode } from 'bl-health/utils'
import AobDialog from 'bl-health/components/AobDialog'
import useStyles from './styles'
import { ThemeConstants } from '../../styles'
import { CustomerInformationSchema } from './validationSchema'
import useCustomerInformationForm from './useCustomerInformationForm'
import { useDMEStore } from '../../store'
import customerInformationInputsToCustomerPayloadAdapter from './customerInformationInputsToCustomerPayloadAdapter'
import TermsAndConditionsWidget from '../../widgets/TermsAndConditionsWidget'
import { createOrUpdateCustomer } from '../../requests'
import { apiErrorResponseAdapter } from '../../utils'
import { formSubmittedEvent } from '../../events'
import { DME } from '../../../form-types'
import EmailVerificationWidget from '../../widgets/EmailVerificationWidget/EmailVerificationWidget'
import useMinWaitTimer from '../../shared/useMinWaitTimer'
import {
  EXISTING_CUSTOMER_PATH,
  PRIVACY_URL,
  TERMS_AND_CONDITIONS_PATH,
} from '../../constants'

const MEMBER_ID_HELP_TEXT =
  'Please include all digits and letters in your Member ID. Do not include dashes or spaces.'

// Minimum time the insurance verification overlay should be displayed
const INSURANCE_OVERLAY_MS = 5000

export interface CustomerInformationFormProps {
  onSuccess(): void
}

type FormError = null | ReturnType<typeof apiErrorResponseAdapter>

const CustomerInformationForm = observer(
  ({ onSuccess }: CustomerInformationFormProps) => {
    const { handleSubmit, control, errors, setValue, getValues } = useForm({
      validationSchema: CustomerInformationSchema,
    })

    const [formErrors, setFormErrors] = useState<FormError>()
    const [customerId, setCustomerId] = useState<number | undefined>(undefined)

    useEffect(() => {
      if (inDebugMode()) {
        window.blDebug = {
          fillCustomerForm: (insuranceStatus: number) => {
            const inputs = customerInformationFormFactory.build(
              {},
              { transient: { insuranceStatus: insuranceStatus as any } }
            )
            Object.keys(inputs).forEach((key) =>
              setValue(key, (inputs as any)[key])
            )
          },
        }
      }

      // Clear the timer when the component unmounts
      return () => timedInsuranceVerificationOverlay.cancel()
    }, [])

    const store = useDMEStore()
    const classes = useStyles()
    const timedInsuranceVerificationOverlay = useMinWaitTimer(
      INSURANCE_OVERLAY_MS,
      () => {
        store.ui.globalComponents.Overlay.show('insuranceVerification')
      },
      () => {
        store.ui.globalComponents.Overlay.close('insuranceVerification')
      }
    )

    const {
      agreedToAOB,
      agreedToTermsAndConditions,
      handleAOBAcknowledgmentChange,
      handleTermsAndConditionsChange,
      onAgreeToAOB,
      onAgreeToTermsAndConditions,
      onDisagreeToAOB,
      onDisagreeToTermsAndConditions,
      openDialog,
      showAOBDialog,
      showTermsAndConditionsDialog,
      submitAllowed,
      setOTPRequired,
      otpRequired,
      setEmailVerificationCompleted,
      completedEmailVerification,
    } = useCustomerInformationForm()

    const tracker = useTracking()

    const resetEmailVerificationFormState = () => {
      setOTPRequired(false)
    }

    const isOTPRequired = (
      response: APIResponse.Customer | APIResponse.CustomerOTPRequired
    ): response is APIResponse.CustomerOTPRequired =>
      (response as APIResponse.CustomerOTPRequired).otpRequired

    const putCustomerSucceeded = (
      response: APIResponse.Customer | APIResponse.CustomerOTPRequired
    ) => {
      timedInsuranceVerificationOverlay.wait(() => onSuccessActions(response))
    }

    const onSuccessActions = (
      response: APIResponse.Customer | APIResponse.CustomerOTPRequired
    ) => {
      if (isOTPRequired(response)) {
        setOTPRequired(true)
        setCustomerId(response.customerId)
        return
      }

      if (
        response.manualReviewRequired !== undefined &&
        response.shippingEligibilityDays !== undefined
      ) {
        store.insurance.setManualReviewRequired(response.manualReviewRequired)
        store.insurance.setShippingEligibilityDays(
          response.shippingEligibilityDays
        )
      }

      const { id, persistenceToken, isExistingPartsCustomer } =
        response as APIResponse.Customer

      if (isExistingPartsCustomer) {
        window.location.assign(EXISTING_CUSTOMER_PATH)
        return
      }

      formSubmittedEvent(tracker)({
        formName: track.FormName.CUSTOMER_INSURANCE_FORM,
        status: track.Status.SUCCESS,
      })
      store.user.update({ ...store.user, id, persistenceToken })

      if (completedEmailVerification) {
        store.ui.globalComponents.Snackbar.show(
          'Email verified. Any change of information has been updated on your account.',
          'success'
        )
      }

      onSuccess()
    }

    const putCustomerFailed = (e: any) => {
      timedInsuranceVerificationOverlay.wait(() => onFailureActions(e))
    }

    const onFailureActions = (e: any) => {
      const formattedError = apiErrorResponseAdapter(e, {
        strategy: store.context.strategy,
      })
      formSubmittedEvent(tracker)({
        formName: track.FormName.CUSTOMER_INSURANCE_FORM,
        status: track.Status.FAIL,
        errorMessage: formattedError.message
          ? formattedError.message
          : JSON.stringify(e),
      })

      setFormErrors(formattedError)
      if (isOTPRequired(e)) {
        setOTPRequired(true)
      }
    }

    const { mutate: putCustomer, isLoading } = useMutation({
      mutationFn: createOrUpdateCustomer,
      onSuccess: putCustomerSucceeded,
      onError: putCustomerFailed,
    })

    const userIsLoadingOrSubmitted = isLoading || store.user.present
    const onSubmit =
      ({ verified }: { verified: boolean }) =>
      (data: DME.CustomerInformationFormInputs) => {
        const payload = customerInformationInputsToCustomerPayloadAdapter({
          ...data,
          aobAcknowledged: agreedToAOB,
          gender: 'female',
          verified,
        })
        store.cart.setAobAcknowledged(agreedToAOB)
        timedInsuranceVerificationOverlay.start()
        // The strategy is used by the backend to know what kind of validation to perform on the baby's DOB field
        putCustomer({ ...payload, strategy: store.context.strategy })
      }

    const formErrorMessage = formErrors?.message

    return (
      <>
        <TermsAndConditions
          clauses={[]}
          dialogContent={<TermsAndConditionsWidget />}
          open={showTermsAndConditionsDialog}
          title="Babylist Durable Medical Equipment Terms & Conditions"
          onAgree={onAgreeToTermsAndConditions}
          onDisagree={onDisagreeToTermsAndConditions}
        />

        <AobDialog
          open={showAOBDialog}
          onAgree={onAgreeToAOB}
          onDisagree={onDisagreeToAOB}
        />

        <form
          onSubmit={handleSubmit(
            onSubmit({ verified: completedEmailVerification }) as any
          )}
        >
          <EmailVerificationWidget
            email={getValues().email}
            customerId={customerId}
            open={otpRequired}
            onClose={() => {
              resetEmailVerificationFormState()
            }}
            onFormSubmitted={() => {
              resetEmailVerificationFormState()
              setEmailVerificationCompleted()
              handleSubmit(onSubmit({ verified: true }) as any)()
            }}
          />

          <Grid container spacing={ThemeConstants.BASE_SPACING}>
            {formErrorMessage && (
              <Grid item xs={12}>
                <Alert severity="error" variant="standard">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: formErrorMessage,
                    }}
                  />
                </Alert>
              </Grid>
            )}

            <Grid item xs={12}>
              <Controller
                fullWidth
                required
                as={TextField}
                autoComplete="given-name"
                control={control}
                disabled={userIsLoadingOrSubmitted}
                error={errors.firstName || !!formErrors?.firstName}
                helperText={errors.firstName?.message || formErrors?.firstName}
                label="Patient's First Name"
                name="firstName"
                rules={{ required: true }}
                type="text"
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                fullWidth
                required
                as={TextField}
                autoComplete="family-name"
                control={control}
                disabled={userIsLoadingOrSubmitted}
                error={errors.lastName || !!formErrors?.lastName}
                helperText={errors.lastName?.message || formErrors?.lastName}
                label="Patient's Last Name"
                name="lastName"
                rules={{ required: true }}
                type="text"
              />
            </Grid>

            <Grid container item spacing={ThemeConstants.BASE_SPACING}>
              <Controller
                required
                as={DateTextField}
                control={control}
                day={{
                  placeholder: 'DD',
                  fullWidth: true,
                  autoComplete: 'bday-day',
                }}
                disabled={userIsLoadingOrSubmitted}
                error={errors.birthDate}
                helperText={errors.birthDate?.message}
                month={{
                  label: "Patient's DOB",
                  placeholder: 'MM',
                  fullWidth: true,
                  autoComplete: 'bday-month',
                }}
                name="birthDate"
                year={{
                  placeholder: 'YYYY',
                  fullWidth: true,
                  autoComplete: 'bday-year',
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                fullWidth
                required
                as={TextField}
                control={control}
                disabled={userIsLoadingOrSubmitted}
                error={!!formErrors?.memberId}
                helperText={formErrors?.memberId || MEMBER_ID_HELP_TEXT}
                label="Member ID"
                name="memberId"
                rules={{ required: true }}
              />
            </Grid>

            <Grid container item spacing={ThemeConstants.BASE_SPACING}>
              <Controller
                required
                as={DateTextField}
                control={control}
                day={{
                  placeholder: 'DD',
                  fullWidth: true,
                }}
                disabled={userIsLoadingOrSubmitted}
                error={errors.dueDate || !!formErrors?.dueDate}
                helperText={errors.dueDate?.message || formErrors?.dueDate}
                month={{
                  label:
                    store.context.strategy == ApplicationStrategy.Pumps
                      ? 'Due Date'
                      : "Baby's DOB",
                  placeholder: 'MM',
                  fullWidth: true,
                }}
                name="dueDate"
                year={{
                  placeholder: 'YYYY',
                  fullWidth: true,
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                fullWidth
                required
                as={TextField}
                control={control}
                disabled={userIsLoadingOrSubmitted}
                error={errors.email || !!formErrors?.email}
                helperText={errors.email?.message || formErrors?.email}
                label="Email"
                name="email"
                rules={{ required: true }}
                type="email"
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                fullWidth
                required
                as={MaskedPhoneNumberTextField}
                control={control}
                disabled={userIsLoadingOrSubmitted}
                error={errors.phoneNumber || !!formErrors?.phoneNumber}
                helperText={
                  errors.phoneNumber?.message || formErrors?.phoneNumber
                }
                label="Phone Number"
                name="phoneNumber"
                rules={{ required: true }}
                type="tel"
              />
            </Grid>

            <Grid item xs={12}>
              <Box mt={-3}>
                <Controller
                  as={BabyUICheckbox}
                  control={control}
                  disabled={userIsLoadingOrSubmitted}
                  label={
                    <Box mb={1.5} mt={3} style={{ fontSize: '14px' }}>
                      I consent for Babylist Health to contact me through
                      automated text messages and live and pre-recorded phone
                      calls in connection with goods and services available on
                      this site. I understand that my consent is not a condition
                      to purchase any goods or services.
                      <br />
                    </Box>
                  }
                  name="smsAllowed"
                />
              </Box>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={agreedToTermsAndConditions}
                      disabled={userIsLoadingOrSubmitted}
                      inputProps={{
                        'aria-label': 'terms & conditions agreement',
                      }}
                      style={{ fontSize: '14px' }}
                      onChange={handleTermsAndConditionsChange}
                    />
                  }
                  label={
                    <span className={classes.termAndConditionsCopyWrapper}>
                      I agree to the&nbsp;
                      <Link
                        href={TERMS_AND_CONDITIONS_PATH}
                        style={{ fontSize: 'inherit' }}
                        target="_blank"
                        variant="body1"
                      >
                        Terms and Conditions
                      </Link>
                      &nbsp;and&nbsp;
                      <Link
                        href={PRIVACY_URL}
                        style={{ fontSize: 'inherit' }}
                        target="_blank"
                        variant="body1"
                      >
                        Privacy Policy
                      </Link>
                    </span>
                  }
                  name="tAndC"
                />
              </div>
              <div>
                <FormControl error={!!formErrors?.aobAcknowledged}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={agreedToAOB}
                        disabled={userIsLoadingOrSubmitted}
                        inputProps={{
                          'aria-label': 'assignment of benefits agreement',
                        }}
                        onChange={handleAOBAcknowledgmentChange}
                      />
                    }
                    label={
                      <span className={classes.termAndConditionsCopyWrapper}>
                        I agree to the&nbsp;
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <Link
                          component="button"
                          style={{ fontSize: 'inherit' }}
                          variant="body1"
                          onClick={(e) => {
                            // Preventing default behavior otherwise it will try to submit the form
                            e.preventDefault()
                            openDialog('aob')
                          }}
                        >
                          Assignment of Benefits
                        </Link>
                      </span>
                    }
                    name="aob"
                  />
                  {formErrors?.aobAcknowledged && (
                    <FormHelperText>
                      {formErrors.aobAcknowledged}
                    </FormHelperText>
                  )}
                </FormControl>
              </div>
            </Grid>
          </Grid>

          <LoadingButton
            fullWidth
            className={classes.verticalMargin}
            color="primary"
            disabled={!submitAllowed || userIsLoadingOrSubmitted}
            loading={isLoading}
            type="submit"
            variant="contained"
          >
            {store.insurance.requiresPrescription ? 'Continue' : 'Checkout'}
          </LoadingButton>
        </form>
      </>
    )
  }
)

export default CustomerInformationForm
